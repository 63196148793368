<template>
  <div>
    <v-form v-model="valid" @submit.prevent="createUser">
      <label class="label_title text-capitalize">{{
        $t("register.registeraccount")
      }}</label>
      <v-text-field
        v-model="fullName"
        :label="$t('user.fullName')"
        filled
        outlined
        color="grey lighten-1"
        required
        :rules="nameRules"
        @change="$store.commit('Authen/SET_FULLNAME', fullName)"
        hide-details
      ></v-text-field>
      <v-select
        v-model="gender"
        :label="$t('user.gender')"
        filled
        outlined
        color="grey lighten-1"
        required
        :items="genderOptions"
        @change="$store.commit('Authen/SET_GENDER', gender)"
        hide-details
      ></v-select>
      <v-btn
        type="submit"
        color="blue-grey"
        block
        class="btn_login mb-5 text-uppercase"
        :loading="loading"
        :disabled="!valid"
        style="margin-top: 15px"
      >
        {{ $t("register.continue") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { GenderType } from "@/plugins/constants";

export default {
  computed: {
    genderOptions() {
      return Object.keys(GenderType).map((key) => ({
        text: this.$t(`register.${key.toLowerCase()}`),
        value: GenderType[key],
      }));
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      fullName: this.$store.getters["Authen/getUser"].fullName,
      gender: this.$store.getters["Authen/getUser"].gender,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (!!v && v.length >= 6) || "Name must be bigger than 6 characters",
      ],
    };
  },
  mounted() {},
  methods: {
    async createUser() {
      this.loading = true;
      try {
        var user = this.$store.getters["Authen/getUser"];
        var id = user.id;
        // var userObj = await this.$dbGet(`users/${id}`);
        var userObj = await this.getUserLite(id);
        if (userObj) {
          user = { ...userObj, ...user };
          // await this.$dbUpdate(`users/${id}`, user);
          await this.$dbSet(`users/${id}/fullName`, user.fullName);
          await this.$dbSet(`users/${id}/gender`, user.gender);
          await this.$dbSet(`users/${id}/avatar`, user.avatar);
          this.$store.commit("Authen/SET_USER", user);
        } else {
          await this.$dbSet(`users/${id}`, user);
        }
        await this.ensurePatientProfileExist();
      } catch (err) {
        alert(err.message);
      }
      await this.sleep(100);
      await this.registerNotificationToken();
      this.loading = false;
      this.$router.go("/home");
    },
  },
};
</script>
